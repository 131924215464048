import React from "react"
import { Link } from "gatsby"

import "./styles.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"

class FormExample extends React.Component {
    state = {
        firstName: "",
        lastName: "",
    }
    handleInputChange = event => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value,
        })
    }
    handleSubmit = event => {
        event.preventDefault()
        alert('Welcome ${this.state.firstName} ${this.state.lastName}')
    }
    render() {
        return (
            <Layout>
                <h1>Hallo</h1>
                <form onSubmit={this.handleSubmit}>
                    <label>
                        First Name
                        <input 
                        type="text" 
                        name="firstName" 
                        value={this.state.firstName}
                        onChange={this.handleInputChange}
                        />
                    </label>
                    <label>
                        Last Name
                        <input 
                        type="text"
                        name="lastName" 
                        value={this.state.lastName}
                        onChange={this.handleInputChange}
                        />
                    </label>
                    <button type="submit">Submit</button>
                </form>
            </Layout>  
        );
    }
}

export default FormExample;
